<template>
    <div>
        <b-card>
            <b-card-header class="p-0">
                <div class="row">
                    <div class="col-md-6 form-group align-self-center">
                        <b-card-title class="mb-0">{{ $t("GENERAL.ADD_ITEM")}}</b-card-title>
                    </div>

                </div>
            </b-card-header>
            <b-form @submit="onSubmit">
                <b-card-body>
                    <b-row>
                        <b-col md class="col-md-6">
                            <b-form-group :label="$t('GENERAL.CODE')">
                                <b-form-input v-model="form.code"
                                              type="text"
                                              :state="validateState('code')"
                                              :placeholder="$t('GENERAL.CODE')"></b-form-input>
                                <b-form-invalid-feedback id="input-2-live-feedback"> {{ $t('GENERAL.CODE_REQ') }} </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col md class="col-md-6">
                            <b-form-group :label="$t('GENERAL.NAME')">
                                <b-form-input v-model="form.name"
                                              type="text"
                                              :state="validateState('name')"
                                              :placeholder="$t('GENERAL.NAME')"></b-form-input>
                                <b-form-invalid-feedback id="input-2-live-feedback"> {{ $t('GENERAL.NAME_REQ') }} </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md class="col-md-6">
                            <b-form-group :label="$t('GENERAL.CATEGORY')">
                                <b-form-select v-model="form.categoryId"
                                               :options="categories"
                                               :state="validateState('categoryId')"
                                               @change="selectCategory"></b-form-select>
                                <b-form-invalid-feedback id="input-2-live-feedback"> {{ $t('GENERAL.CATEGORY_REQ') }} </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col md class="col-md-6">
                            <b-form-group :label="$t('GENERAL.SUBCATEGORY')">
                                <b-form-select v-model="form.subcategoryId"
                                               :options="subcategories"
                                               :disabled="toggleDisabledSubcategoriesSelect"></b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md>
                            <b-form-group :label="$t('GENERAL.AVAILABLE_QUANTITY')">
                                <b-form-input v-model="form.availableQuantity"
                                              type="number"
                                              step="0.001"
                                              :placeholder="$t('GENERAL.AVAILABLE_QUANTITY')"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md>
                            <b-form-group :label="$t('GENERAL.RETAIL_PRICE')">
                                <b-form-input v-model="form.retailPrice"
                                              type="number"
                                              step="0.001"
                                              :state="validateState('retailPrice')"
                                              :placeholder="$t('GENERAL.RETAIL_PRICE')"></b-form-input>
                                <b-form-invalid-feedback id="input-2-live-feedback"> {{ $t('GENERAL.RETAIL_PRICE_REQ') }} </b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col md>
                            <b-form-group :label="$t('GENERAL.DISCOUNT_PERCENTAGE')">
                                <b-form-input v-model="form.discountPercentage"
                                              step="0.001"
                                              type="number"
                                              :placeholder="$t('GENERAL.DISCOUNT_PERCENTAGE')"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md>
                            <b-form-group :label="$t('GENERAL.PHOTOS')">
                                <b-form-file multiple
                                             accept="image/*"
                                             v-model="form.selectedFiles"
                                             ref="photosInput"
                                             :browse-text="$t('GENERAL.BROWSE')"
                                             :placeholder="$t('GENERAL.CHOOSE_PHOTOS')"
                                             :drop-placeholder="$t('GENERAL.DROP_PHOTOS_HERE')">
                                </b-form-file>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="form.images.length > 0">
                        <b-col md class="form-group">
                            <b-row>
                                <b-col class="form-group" md="2" v-for="(image, index) in form.images" :key="index">
                                    <b-col class="border border-secondary rounded p-2">
                                        <i :id="'delete-' + index"
                                           class="flaticon2-cross text-danger icon-sm float-right pointer"
                                           @click="removeImage(index)"></i>
                                        <b-tooltip :target="'delete-' + index" variant="dark">{{ $t("GENERAL.DELETE") }}</b-tooltip>
                                        <b-img class="border border-secondary rounded mb-2 mt-2" :src="image.type + image.file" fluid-grow></b-img>
                                        <b-form-checkbox switch inline v-model="image.isMain" @change="isMainChange(!image.isMain, index)" size="lg" class="mr-10"><span class="small">{{ $t("GENERAL.IS_MAIN") }}</span></b-form-checkbox>
                                        <b-form-checkbox switch inline v-model="image.isHover" @change="isHoverChange(!image.isHover, index)" size="lg" class="mr-10"><span class="small">{{ $t("GENERAL.IS_HOVER") }}</span></b-form-checkbox>
                                    </b-col>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md>
                            <b-form-group :label="$t('GENERAL.DESCRIPTION')">
                                <b-form-textarea v-model="form.description"
                                                 :placeholder="$t('GENERAL.DESCRIPTION')"
                                                 style="min-height: 40px"
                                                 rows="5"></b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md>
                            <b-form-group>
                                <b-form-checkbox switch inline v-model="form.isActive" size="lg" class="mr-10"><span class="small">{{ $t("GENERAL.IS_ACTIVE") }}</span></b-form-checkbox>
                                <b-form-checkbox switch inline v-model="form.isFeatured" size="lg" class="mr-10"><span class="small">{{ $t("GENERAL.IS_FEATURED") }}</span></b-form-checkbox>
                                <b-form-checkbox switch inline v-model="form.isFreeShipping" size="lg" class="mr-10"><span class="small">{{ $t("GENERAL.IS_FREE_SHIPPING") }}</span></b-form-checkbox>
                                <b-form-checkbox switch inline v-model="form.isDiscounted" size="lg" :disabled="toggleDisabledIsDiscounted"><span class="small">{{ $t("GENERAL.IS_DISCOUNTED") }}</span></b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="form.attributes.length > 0">
                        <b-col md="12" class="form-group mt-5 font-weight-bold">
                            {{ $t("GENERAL.ITEM_ATTRIBUTES").toUpperCase() }}
                            <hr />
                        </b-col>
                        <b-col md="4" v-for="attribute in form.attributes" :key="attribute.id">
                            <b-form-group :label="attribute.name">
                                <b-row v-for="(itemAttribute, index) in itemAttributesByAttributeId(attribute.id)" :key="index">
                                    <b-col :xs="attribute.affectsPrice ? 3 : 5">
                                        <b-form-input type="text"
                                                      v-if="attribute.inputType == 1"
                                                      v-model="itemAttribute.textValue"
                                                      :placeholder="attribute.name"></b-form-input>
                                        <b-form-input step="0.001"
                                                      type="number"
                                                      v-else-if="attribute.inputType == 2"
                                                      v-model="itemAttribute.numericValue"
                                                      :placeholder="attribute.name"></b-form-input>
                                        <b-form-input type="text"
                                                      v-else
                                                      :placeholder="attribute.name"
                                                      v-model="itemAttribute.textValue"
                                                      @click="switchToColorInputType"></b-form-input>
                                    </b-col>
                                    <b-col xs="3"
                                           v-if="attribute.affectsPrice">
                                        <b-form-input step="0.001"
                                                      type="number"
                                                      v-model="itemAttribute.price"
                                                      :placeholder="$t('GENERAL.PRICE')"></b-form-input>
                                    </b-col>
                                    <!--<b-col :xs="attribute.affectsPrice ? 3 : 4">-->
                                        <!--  plain -> stavi kad budes htio sakrit -->
                                        <!--<b-form-file accept="image/*"
                                                     hidden
                                                     v-model="itemAttribute.inputFile"
                                                     :browse-text="$t('GENERAL.BROWSE')"
                                                     :placeholder="$t('GENERAL.CHOOSE_PHOTO')"
                                                     no-drop>
                                        </b-form-file>
                                    </b-col>-->
                                    <b-col xs="3" 
                                           v-if="attribute.attributeTypeId == 2">
                                        <b-button v-if="index == (itemAttributesByAttributeId(attribute.id).length - 1)"
                                                  @click="duplicateItemAttribute(index, attribute.id)"
                                                  variant="primary">
                                            <i class="flaticon2-plus"></i>
                                        </b-button>
                                        <b-button v-if="itemAttributesByAttributeId(attribute.id).length > 1 && index == (itemAttributesByAttributeId(attribute.id).length - 1)"
                                                  @click="deleteItemAttribute(index, attribute.id)"
                                                  variant="danger">
                                            <i class="flaticon-delete"></i>
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-col>
                    </b-row>

                </b-card-body>
                <b-card-footer class="text-right">
                    <router-link :to="{name: 'items_index'}">
                        <b-button type="submit" variant="secondary" class="float-left" :disabled="submitting">
                            <b-spinner v-if="submitting" small type="grow"></b-spinner>
                            {{ $t("GENERAL.CANCEL") }}
                        </b-button>
                    </router-link>
                    <!--<b-button type="submit" variant="primary" class="float-right">{{ $t("GENERAL.SAVE") }}</b-button>-->
                    <b-button type="submit" variant="outline-primary" class="mr-5" :disabled="submitting">
                        <b-spinner v-if="submitting" small type="grow"></b-spinner>
                        {{ $t("GENERAL.SAVE_AND_CONTINUE") }}
                    </b-button>
                    <!-- TODO: SKontati zasto je inverzna ova glupost sa dugmadima -->
                    <b-button type="submit" variant="primary" class="" :disabled="submitting" @click="continueWhenSaved">
                        <b-spinner v-if="submitting" small type="grow"></b-spinner>
                        {{ $t("GENERAL.SAVE") }}
                    </b-button>
                </b-card-footer>
            </b-form>
        </b-card>
    </div>
</template>

<script>

    import { validationMixin } from "vuelidate";
    import { required } from "vuelidate/lib/validators";

    import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
    import { POST_ITEMS } from "@/core/services/store/items.module";
    import { GET_CATEGORIES } from "@/core/services/store/categories.module";
    import { GET_SUBCATEGORIES } from "@/core/services/store/subcategories.module";
    import { GET_ATTRIBUTES } from "@/core/services/store/attributes.module";

    export default {
        name: "add",
        mixins: [validationMixin],
        components: {
        },
        data() {
            return {
                form: {
                    code: null,
                    name: null,
                    categoryId: null,
                    subcategoryId: null,
                    description: null,
                    retailPrice: null,
                    isActive: true,
                    availableQuantity: null,
                    isFreeShipping: true,
                    isDiscounted: false,
                    isFeatured: false,
                    discountPercentage: null,
                    selectedFiles: [],
                    images: [],
                    attributes: [],
                    itemAttributes: [],
                    itemAttributesImages: []
                },
                submitting: false,
                saveAndContinue: false
            }
        },
        computed: {
            toggleDisabledSubcategoriesSelect() {
                return this.form.categoryId == null;
            },
            toggleDisabledIsDiscounted() {
                return this.form.discountPercentage == null;
            },
            categories() {
                let cats = [{ value: null, text: this.$t("GENERAL.CHOOSE_CATEGORY") }];
                this.$store.getters.categories.sort((a, b) => { a.ordinalNumber - b.ordinalNumber }).forEach(c => cats.push({ value: c.id, text: c.categoryTranslations[0].name }))
                return cats;
            },
            subcategories() {
                let subcats = [{ value: null, text: this.$t("GENERAL.CHOOSE_SUBCATEGORY") }];
                this.$store.getters.subcategories.sort((a, b) => { a.ordinalNumber - b.ordinalNumber }).forEach(c => subcats.push({ value: c.id, text: c.subcategoryTranslations[0].name }))
                return subcats;
            }
        },
        methods: {
            validateState(name) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            onSubmit(evt) {
                evt.preventDefault()

                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                this.submitting = true;

                //ovo ne radi, za slike dodje NULL na server 

                //this.form.itemAttributes.filter(x => x.inputFile).forEach(x => {
                //    x.fileName = x.inputFile.name;
                //    getBase64(x.inputFile).then(base64 => {
                //        x.file = base64.substring(base64.indexOf(',') + 1);
                //    });
                //});

                let item = {
                    code: this.form.code,
                    isStockRemove: true,
                    isFreeShipping: this.form.isFreeShipping,
                    isActive: this.form.isActive,
                    groupId: null,
                    subcategoryId: this.form.subcategoryId,
                    categoryId: this.form.categoryId,
                    measurementUnitId: 1,
                    storeItemsInsertRequest: [{
                        storeId: 1,
                        itemId: 0,
                        retailPrice: this.form.retailPrice,
                        wholeSalePrice: null,
                        availableQuantity: this.form.availableQuantity,
                        isInfinite: false,
                        isActive: this.form.isActive,
                        isFeatured: this.form.isFeatured,
                        isDiscounted: this.form.isDiscounted,
                        discountPercentage: this.form.discountPercentage,
                        storeItemTaxesInsertRequest: [{ taxId: 1, storeItemId: 0 }]
                    }],
                    itemTranslationsInsertRequest: [
                        {
                            languageId: 1,
                            itemId: 0,
                            name: this.form.name,
                            description: this.form.description
                        }
                    ],
                    imagesInsertRequest: this.form.images,
                    itemAttributesInsertRequest: this.form.itemAttributes.filter(ia => ia.numericValue || ia.textValue)
                };

                this.$store.dispatch(POST_ITEMS, item).then((response) => {

                    //if response == false prikazati error poruku ukoliko je true prikazati success poruku
                    if (response == true) {
                        if (this.saveAndContinue == true) {
                            this.$router.push("/items");
                        }
                        else {
                            this.$v.form.$reset();
                            this.clearForm();
                        }
                    }

                    this.saveAndContinue = this.submitting = false;
                });
            },
            selectCategory() {
                this.form.subcategoryId = null;

                if (this.form.categoryId != null) {
                    this.$store.dispatch(GET_SUBCATEGORIES, this.form.categoryId);
                    this.$store.dispatch(GET_ATTRIBUTES, { categoryId: this.form.categoryId }).then(() => {
                        this.form.attributes = this.$store.getters.attributes.map(a => { return { id: a.id, name: a.attributeTranslations[0].name, attributeTypeId: a.attributeTypeId, displayOrder: a.displayOrder, inputType: a.inputType, affectsPrice: a.affectsPrice } });
                        this.form.itemAttributes = this.$store.getters.attributes.map(a => { return { id: 0, itemId: 0, storeId: 1, attributeId: a.id, textValue: null, numericValue: null, price: null, file: null, fileName: null, inputFile: null } });
                    });
                }
                else {
                    this.form.attributes = this.form.itemAttributes = [];
                }
            },
            continueWhenSaved() {
                this.saveAndContinue = true;
            },
            clearForm() {
                this.form.name = this.form.code = this.form.availableQuantity = this.form.retailPrice = this.form.discountPercentage = this.form.description = null;
                this.form.images = this.form.selectedFiles = [];
                this.form.isActive = this.form.isFreeShipping = true;
                this.form.isDiscounted = false;
            },
            removeImage(index) {
                this.form.images.splice(index, 1);
            },
            isMainChange(isMain, index) {

                if (isMain) {
                    this.form.images.forEach(i => { i.isMain = false });
                    this.form.images[index].isMain = true;
                }
            },
            isHoverChange(isHover, index) {

                if (isHover) {
                    this.form.images.forEach(i => { i.isHover = false });
                    this.form.images[index].isHover = true;
                }
            },
            itemAttributesByAttributeId(attributeId) {
                return this.form.itemAttributes.filter(ia => ia.attributeId == attributeId);
            },
            switchToColorInputType(e) {
                if (e.target.type == "color")
                    return;

                e.target.type = "color";
                e.target.style = "margin-bottom: 5px;";
            },
            duplicateItemAttribute(index, attributeId) {

                let itemAttribute = this.itemAttributesByAttributeId(attributeId)[index];

                if (!itemAttribute.textValue && !itemAttribute.numericValue)
                    return;

                this.form.itemAttributes.push({ id: 0, itemId: 0, storeId: 1, attributeId: itemAttribute.attributeId, textValue: null, numericValue: null, price: null, file: null, fileName: null, inputFile: null });
            },
            deleteItemAttribute(index, attributeId) {

                let itemAttribute = this.itemAttributesByAttributeId(attributeId)[index];

                let indexToDelete = this.form.itemAttributes.indexOf(itemAttribute);

                this.form.itemAttributes.splice(indexToDelete, 1);
            },
        },
        validations: {
            form: {
                code: {
                    required,
                },
                name: {
                    required,
                },
                categoryId: {
                    required,
                },
                retailPrice: {
                    required,
                }

            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.ITEMS"), route: { name: "items_index" } }, { title: this.$t("GENERAL.ADDING") }]);
        },
        created() {
            this.$store.dispatch(GET_CATEGORIES);
        },
        watch: {
            "form.selectedFiles"() {
                this.form.selectedFiles.forEach(sf => {
                    if (!this.form.images.find(i => i.fileName == sf.name)) {
                        getBase64(sf).then(base64 => {
                            this.form.images.push({ type: base64.substring(0, base64.indexOf(',') + 1), file: base64.substring(base64.indexOf(',') + 1), fileName: sf.name, isMain: false, isHover: false, itemId: 0 });

                            if (!this.form.images.find(i => i.isMain)) {
                                this.form.images[0].isMain = true;
                            }
                        });
                    }
                });

                this.$refs.photosInput.reset();
            },
        }
    };


    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
